import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
  // IconButton,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from './form';
import { Form } from 'react-final-form';
import { modules } from '../../../store/actions';
import { connect } from 'react-redux';
import arrayMutators from 'final-form-arrays';
// import { formValidation } from './validator';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku:null,
      sku_contract: null,
      status:false
    }
  }
  
  async componentDidMount(){
    const {sku_contract, sku_visit} = this.props.match.params;
    this.props.getModuleData({sku: sku_visit});
    this.setState({sku:sku_visit, sku_contract : sku_contract})
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.update_request !== this.props.update_request) {
      if(this.props.update_request.success){
        this.props.getModuleData({sku: this.state.sku});
        // this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits`);
      }
    }
    if (prevProps.element !== this.props.element) {
      this.setState({status:this.props.element.status });
    }

    if(prevProps.approved !== this.props.approved){
      if( this.props.approved.success){
        this.setState(prevState => ({
          status: !prevState.status
        }));
      }
    }
  }

  onSubmit = async values => {
    this.props.updateAnswers({sku: this.state.sku, data: values });
    // this.props.updateAnswers({sku: this.state.sku, data: values.answers });
    // for (const key in values.images) {
    //   if (Object.hasOwnProperty.call(values.images, key)) {
    //     const data = values.images[key];
    //     this.props.uploadImage({sku: this.state.sku, data: values.answers, key: key, images: data.images});
    //   }
    // }
  }

  handleChangeStatus = (event) => {
    const data = {visit_id:this.state.sku, status:event.target.checked? "Approved" : "Confirmed"}
    this.props.approvedVisit({ data: data });
  };


  render() {

    const buttonSx = {
      ...(this.props.update_request.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              // validate={values => formValidation.validateForm(values)}
              // initialValues={[]}
              mutators={{ ...arrayMutators }}
              initialValues={this.props.element.answers_json ? this.props.element.answers_json: {}}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        {/* <IconButton onClick={ () => this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits`) }>
                          <ChevronLeftIcon />
                        </IconButton> */}
                        <Typography variant="h4" id="tableTitle" component="div">
                          {this.props.element.business_branch}
                          <Typography variant="caption" display="block" gutterBottom>
                          {this.props.element.survey}
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <FormGroup>
                        <FormControlLabel 
                          control={
                          <Switch 
                          checked={this.state.status || false}
                          onChange={(event) => this.handleChangeStatus(event)} />} 
                          label="Visita Aprobada" 
                          disabled={this.props.approved.loading}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits`)}}
                        disabled={this.props.update_request.loading }
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={buttonSx}
                        disabled={this.props.update_request.loading || this.props.status_element.loading}
                        className={classes.button}
                        type="submit"
                      >
                        Guardar
                        {this.props.update_request.loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData formValues={values} visit_id={this.state.sku}/>
                  
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}

                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAnswers:(payload) =>{
      dispatch(modules.visits.updateAnswers(payload))
    },
    uploadImage:(payload) =>{
      dispatch(modules.visits.uploadImage(payload))
    },
    getModuleData:(payload) =>{
      dispatch(modules.visits.answers(payload))
    },
    approvedVisit:(payload) =>{
      dispatch(modules.visits.approved(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    approved: state.module_visits.approved,
    update_request: state.module_visits.update_answers,
    status_element: state.module_visits.status_element,
    element: state.module_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
