import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Chip,
  FormHelperText
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, TextEditor, Select, Slider, TimePicker, DatePicker, RadioGroup, File} from '../../../../components/inputs';
import { connect } from 'react-redux';
import ImageList from '../../../../components/image-list';
import { FieldArray } from 'react-final-form-arrays';
import { visits } from '../../../../store/actions/modules';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    const types = {
      Binaria: { component: RadioGroup , type:"text" },
      Escala: { component: Slider , type:"text" },
      Abierta: { component: TextEditor , type:"text" },
      Imagen: { component: File , type:"file" },
      Hora: { component: TimePicker , type:"text" },
      Fecha: { component: DatePicker , type:"text" },
      Multiple: { component: Select , type:"text" },
      Numerica: { component: Input , type:"text" },
    };

    const marks  =[
      {
        value: 1,
        label: '😡'
      },
      {
        value: 2,
        label: '🙁'
      },
      {
        value: 3,
        label: '🤔'
      },
      {
        value: 4,
        label: '🙂'
      },
      {
        value: 5,
        label: '🥰'
      }
    ];

    const form  = {
      Binaria: true,
      Escala: 1,
      Abierta: null,
      Imagen: [],
      Hora: null,
      Fecha: null,
      Multiple: null,
      Numerica: null,
    };

    this.state = {
      slug:null,
      types: types,
      marks:marks,
      form:form
    }
  }

  getTitle = (key) => {
    // const catalog = this.props.element.categories.find( catalog => catalog.value === key ); 
    const catalog = this.props.element.categories.find( catalog => catalog.value === key || catalog.id === Number(key));
    return catalog ? catalog.text : key;
  }
  
  getOptions(question){
    let options = [];
    if(question.tipo === "Multiple"){
      if(question.opciones.length > 0){
        options = question.opciones.map( (element) => ({ text:element.opcion, value:element.opcion}))
        if(question.preset_opciones.otro){
          options.push({text:"Otro", value:"Otro"});
        }
      }
      if(question.preset_opciones.no_aplica){
        options.push({text:"No aplica", value:"NA"});
      }
    }
    if(question.tipo === "Binaria"){
      options=[{value: 'Sí',label: 'Si'},{value: 'No',label: 'No'}];
      if(question.preset_opciones.no_aplica){
        options.push({label:"No aplica", value:"NA"});
      }
    }
    return options;
  }
  render() {
    const {formValues, visit_id} = this.props;

    const required = (value) => (value ? undefined : "Respuesta requerida");

    return (
      
      <Grid item container spacing={0}>
        
        {
        Object.keys(this.props.element.questions_json ? this.props.element.questions_json : []).map((key,i)=>{
        return (
          <Card variant="outlined"  sx={{width: '100%'}} key={i}>
            <CardHeader title={this.getTitle(key)}/>
            <CardContent>
              <Grid item xs={12} container spacing={2}>
              {
                this.props.element.questions_json[key].length > 0
                ?
                  this.props.element.questions_json[key].map((question, index) => (
                  <Grid item xs={12} key={index}>
                    <Paper className={styles.paper} variant="outlined">
                      <Stack spacing={3} direction="row" sx={{ mb: 1 }} alignItems="center">
                        { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.inicial}</Typography>: null}
                        { question.tipo !== "Imagen"?
                          <Field
                            validate={(question.obligatorio ? required : null) }
                            required={question.obligatorio}
                            name={`answers.question_${question.id}.respuesta`}
                            clearable={1}
                            // name={question.tipo}
                            // name={`[${question.id - 1}].test`}
                            component={this.state.types[question.tipo].component}
                            type={this.state.types[question.tipo].type}
                            label={question.titulo}
                            //for slider
                            marks ={this.state.marks}
                            min={0}
                            max={5}
                            // for select
                            options={ question.tipo === "Multiple" || question.tipo === "Binaria" ? this.getOptions(question) : null}
                          />
                          :
                          <Field
                            // validate={(question.obligatorio ? required : null) }
                            // required={question.obligatorio}
                            name={`images.question_${question.id}.images`}
                            component={this.state.types[question.tipo].component}
                            type={this.state.types[question.tipo].type}
                            label={question.titulo}
                            //for slider
                            accept="image/*"
                            marks ={this.state.marks}
                            min={0}
                            max={5}
                            // for select
                            options={ question.tipo === "Multiple" || question.tipo === "Binaria" ? this.getOptions(question) : null}
                          />
                        }
                        { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.final}</Typography>: null}
                        { question.descripcion ? <FormHelperText> {question.descripcion} </FormHelperText>: null}
                      </Stack>
                        <pre>{JSON.stringify(this.props.element.answers_json['question_'+question.id], 0, 2)}</pre>
                        { question.tipo === "Imagen" && this.props.element.answers_json.answers['question_'+question.id].respuesta && formValues.answers['question_'+question.id].respuesta.length > 0? 
                        <FieldArray
                          name={`answers.question_${question.id}.respuesta`}
                          render={({ fields }) => (
                          <React.Fragment>
                          {formValues.answers['question_'+question.id].respuesta.map((file,index) => (
                            <Chip key={index} disabled={this.props.remove_image.loading} label={`Evidencia # ${index + 1}`} variant="outlined" onDelete={ () => {fields.remove(index); this.props.removeImage({question_id: question.id,visit_id:visit_id,url: file.url})}} />
                          ))}
                          <div className={styles.img}>
                            {/* <pre>{JSON.stringify(fields.value, 0, 2)}</pre> */}
                            <ImageList images={formValues.answers['question_'+question.id].respuesta} index={"url_temp"}/>
                          </div>
                          </React.Fragment>
                        )}/>
                        : null}
                      </Paper>
                  </Grid>
                  ))
                // <pre>{JSON.stringify(this.props.element.questions[key], 0, 2)}</pre>
                :
                null
              }
              </Grid>
            </CardContent>
            </Card>
          )
        })
      }

      </Grid>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    removeImage:(payload) =>{
      dispatch(visits.removeImage(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    status_element: state.module_visits.status_element,
    remove_image: state.module_visits.remove_image,
    element: state.module_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default _component;
